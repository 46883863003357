<template>
  <div class="teeth-map w-full h-full justify-center mode-products" data-mounted="1" data-mountedmode="PRODUCTS">
    <div>
      <div v-if="teeth" style="margin-left: 20px;float: left;">
      <span>
        <template v-for="(tooth, index) in Object.entries(teeth)">
          <Tooth :tooth="tooth[0]" v-if="tooth[1].class && tooth[1].class.includes('blink')" />
        </template>
      </span>
      </div>
    </div>
    <TeethMap />
  </div>
</template>

<style scoped>
@import url('@/assets/css/teeth.css');

.toggleSwitch span span {
  display: none;
}

.toggleSwitch {
  display: inline-block;
  height: 18px;
  position: relative;
  overflow: visible;
  padding: 0;
  cursor: pointer;
  width: 200px;
  background-color: #fafafa;
  border: 1px solid #ccc;
  border-radius:5px;
  height:34px;
}
.toggleSwitch * {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.toggleSwitch label,
.toggleSwitch > span {
  line-height: 20px;
  height: 20px;
  vertical-align: middle;
}
.toggleSwitch input:focus ~ a,
.toggleSwitch input:focus + label {
  outline: none;
}
.toggleSwitch label {
  position: relative;
  z-index: 3;
  display: block;
  width: 100%;
}
.toggleSwitch input {
  position: absolute;
  opacity: 0;
  z-index: 5;
}
.toggleSwitch > span {
  position: absolute;
  left: 0;
  width: calc(100% - 6px);
  margin: 0;
  text-align: left;
  white-space: nowrap;
  margin:0 3px;
}
.toggleSwitch > span span {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;
  display: block;
  width: 50%;
  margin-left: 50px;
  text-align: left;
  font-size: 0.9em;
  width: auto;
  left: 0;
  top: -1px;
  opacity: 1;
  width:40%;
  text-align: center;
  line-height:34px;
}
.toggleSwitch a {
  position: absolute;
  right: 50%;
  z-index: 4;
  display: block;
  top: 3px;
  bottom: 3px;
  padding: 0;
  left: 3px;
  width: 50%;
  background-color: #666;
  border-radius: 4px;
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}
.toggleSwitch > span span:first-of-type {
  color: #FFF;
  opacity: 1;
  left: 0;
  margin: 0;
  width: 50%;
}
.toggleSwitch > span span:last-of-type {
  left:auto;
  right:0;
  color: #999;
  margin: 0;
  width: 50%;
}
.toggleSwitch > span:before {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: -2px;
  /* background-color: #fafafa;
  border: 1px solid #ccc; */
  border-radius: 30px;
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}
.toggleSwitch input:checked ~ a {
  left: calc(50% - 3px);
}
.toggleSwitch input:checked ~ span:before {
  /* border-color: #0097D1;
  box-shadow: inset 0 0 0 30px #0097D1; */
}
.toggleSwitch input:checked ~ span span:first-of-type {
  left:0;
  color:#999;
}
.toggleSwitch input:checked ~ span span:last-of-type {
  /* opacity: 1;
  color: #fff;	 */
  color:#FFF;
}
/* Switch Sizes */
.toggleSwitch.large {
  width: 60px;
  height: 27px;
}
.toggleSwitch.large a {
  width: 27px;
}
.toggleSwitch.large > span {
  height: 29px;
  line-height: 28px;
}
.toggleSwitch.large input:checked ~ a {
  left: 41px;
}
.toggleSwitch.large > span span {
  font-size: 1.1em;
}
.toggleSwitch.large > span span:first-of-type {
  left: 50%;
}
.toggleSwitch.xlarge {
  width: 80px;
  height: 36px;
}
.toggleSwitch.xlarge a {
  width: 36px;
}
.toggleSwitch.xlarge > span {
  height: 38px;
  line-height: 37px;
}
.toggleSwitch.xlarge input:checked ~ a {
  left: 52px;
}
.toggleSwitch.xlarge > span span {
  font-size: 1.4em;
}
.toggleSwitch.xlarge > span span:first-of-type {
  left: 50%;
}
</style>

<script lang="ts">
// onclick on #Dents g, add .blink class to g

// @ts-ignore
import { globalEvents } from '@/utils/GlobalEvents.js';
import {Component, Vue, Watch} from "vue-property-decorator";
import Tooth from "@/components/Tooth.vue";
import TeethMap from "@/assets/svg/TeethMap.vue";

@Component({
  components: {Tooth, TeethMap}
})
export default class Teeth extends Vue {
  private selectedTeeth: any[] = [];

  private fdiEnabled = true;

  private teeth: object = Vue.observable({
    '48': { id: 'd48', class: '' },
    '47': { id: 'd47', class: '' },
    '46': { id: 'd46', class: '' },
    '45': { id: 'd45', class: '' },
    '44': { id: 'd44', class: '' },
    '43': { id: 'd43', class: '' },
    '42': { id: 'd42', class: '' },
    '41': { id: 'd41', class: '' },
    '31': { id: 'd31', class: '' },
    '32': { id: 'd32', class: '' },
    '33': { id: 'd33', class: '' },
    '34': { id: 'd34', class: '' },
    '35': { id: 'd35', class: '' },
    '36': { id: 'd36', class: '' },
    '37': { id: 'd37', class: '' },
    '38': { id: 'd38', class: '' },
    '18': { id: 'd18', class: '' },
    '17': { id: 'd17', class: '' },
    '16': { id: 'd16', class: '' },
    '15': { id: 'd15', class: '' },
    '14': { id: 'd14', class: '' },
    '13': { id: 'd13', class: '' },
    '12': { id: 'd12', class: '' },
    '11': { id: 'd11', class: '' },
    '21': { id: 'd21', class: '' },
    '22': { id: 'd22', class: '' },
    '23': { id: 'd23', class: '' },
    '24': { id: 'd24', class: '' },
    '25': { id: 'd25', class: '' },
    '26': { id: 'd26', class: '' },
    '27': { id: 'd27', class: '' },
    '28': { id: 'd28', class: '' },
    'mandibule': { id: 'mandibule', class: '' },
    'maxillaire':  { id: 'maxillaire', class: '' },
  })

  @Watch('fdiEnabled')
  updateState() {
    Vue.prototype.$state.norm = this.fdiEnabled ? 'fdi' : 'uns';
    // @ts-ignore
    document.querySelector('body').setAttribute('norm', Vue.prototype.$state.norm);
  }

  private updateDOMClass(id: string, newClass: string) {
    // Gestion des multidents
    if (id.includes('-')) {
      const [firstId, secondId] = id.split('-');
      // @ts-ignore
      const firstClass = this.teeth[firstId.substring(1)]?.class || '';
      // @ts-ignore
      const secondClass = this.teeth[secondId.substring(1)]?.class || '';

      // Trouver les classes communes
      const commonClasses = firstClass.split(' ').filter((cls: any) => secondClass.includes(cls)).join(' ');

      // Appliquer les classes communes aux éléments multidents
      const multiElement = document.getElementById(id);
      if (multiElement) {
        multiElement.setAttribute('class', commonClasses);
      }
    } else {
      // Gestion des dents individuelles (code existant)
      const elements = document.querySelectorAll(`[id^="${id}"]`);
      console.log(elements, `[id^="#d${id}"]`);
      elements.forEach((el: Element) => {
        el.setAttribute('class', newClass);
      });
    }
  }

  get displayedTeethCount() {
    return Object.values(this.teeth).filter(tooth => tooth.class && tooth.class.includes('blink')).length;
  }

  blink(id: string) {
    // @ts-ignore
    if(this.teeth[id].class.includes('blink')) {
      // @ts-ignore
      this.teeth[id].class = this.teeth[id].class.replace('blink', '');
      // remove from selectedTeeth
      const index = this.selectedTeeth.indexOf(id);
      if (index > -1) {
        this.selectedTeeth.splice(index, 1);
      }
    } else {
      // @ts-ignore
      this.teeth[id].class = (this.teeth[id].class.length > 0 ? this.teeth[id].class + ' ' : '') + 'blink';
      this.selectedTeeth.push(id);
    }
  }

  get works() {
    return Vue.prototype.$state.works;
  }

  @Watch('works', {
    deep: true
  })
  handleChange() {
    Object.keys(this.teeth).forEach(key => {
      // @ts-ignore
      this.teeth[key].class = '';
    });

    // @ts-ignore
    this.works.forEach(work => {
      const renderClasses = work.product.render.join(' ');
      // @ts-ignore
      work.teeth.forEach(tooth => {
        if (typeof tooth === 'string' && tooth.includes('-')) {
          const [firstId, secondId] = tooth.split('-');
          // @ts-ignore
          this.teeth[firstId.substring(1)].class += ' ' + renderClasses;
          // @ts-ignore
          this.teeth[secondId.substring(1)].class += ' ' + renderClasses;
          this.updateDOMClass(tooth, this.getCommonClasses(firstId.substring(1), secondId.substring(1)));
        } else {
          // @ts-ignore
          this.teeth[tooth].class += ' ' + renderClasses;
        }
      });
    });
  }

  private getCommonClasses(id1: string, id2: string): string {
    // @ts-ignore
    const class1 = this.teeth[id1]?.class || '';
    // @ts-ignore
    const class2 = this.teeth[id2]?.class || '';
    return class1.split(' ').filter((cls: any) => class2.includes(cls)).join(' ');
  }

  async mounted() {
    // @ts-ignore
    document.querySelector('.teeth-map').addEventListener('click', event => {
      // @ts-ignore
      if(event.target.parentNode.tagName == 'g') {
        // @ts-ignore
        const gNode = event.target.parentNode;
        // @ts-ignore
        let gNodeId = event.target.parentNode.id;

        // @ts-ignore
        if(gNodeId.trim().length === 0 && event.target.parentNode.parentNode == 'g') {
          // @ts-ignore
          gNodeId = event.target.parentNode.parentNode.id;
        }

        //this.blink(gNodeId.substring(1));

        this.$emit('toothClick', gNodeId.substring(1));
      }
    });

    globalEvents.$on('unselect-all-teeth', () => {
      this.selectedTeeth = [];
      Object.keys(this.teeth).forEach((id) => {
        // @ts-ignore
        if(this.teeth[id].class.includes('blink')) {
          // @ts-ignore
          this.teeth[id].class = '';
          // @ts-ignore
          this.teeth[id].works = {};
        }
      });
    });

    Object.keys(this.teeth).forEach(key => {
      this.$watch(
          // @ts-ignore
          () => this.teeth[key].class,
          (newVal) => {
            // @ts-ignore
            this.updateDOMClass(this.teeth[key].id, newVal);

            // Mettre à jour les multidents associés
            Object.keys(this.teeth).forEach(otherKey => {
              if (otherKey !== key) {
                const multiId = `d${key}-d${otherKey}`;
                this.updateDOMClass(multiId, '');
              }
            });
          }
      );
    });
  }
}
</script>