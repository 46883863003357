<template>
  <div id="app" v-if="dpNodes">
    <!--<Steps />-->
    <div class="row">
      <div class="col-2">
        <Sidebar :nodes="dpNodes" :selectedNodeId="selectedNodeId" @renderFinalProducts="renderFinalProducts" @selectNode="selectedNodeId = $event" />
      </div>
      <div class="col" style="padding-top: 60px;">
        <!--<span class="globalMessage">{{ globalMessage }}</span>-->
        <span class="globalMessage">Dans le calque SVG, chaque dent doit avoir comme identifiant : dXX avec XX = numéro de la dent</span>
        <Teeth style="margin-left: auto; margin-right: auto;" ref="teeth" @toothClick="toothClick" />
        <Products v-if="dpNodes[0]" :products="finalProducts" :selectedProductId="selectedProductId" @selectedProduct="selectProduct" />
      </div>
      <div class="col-2">
        <Resume />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Steps from "@/components/Steps.vue";
import {Component, Vue} from "vue-property-decorator";
import Teeth from "@/components/Teeth.vue";
import Sidebar from "./components/Sidebar.vue";
import Resume from "@/components/Resume.vue";
import Products from "@/components/Products.vue";
// @ts-ignore
import { globalEvents } from '@/utils/GlobalEvents.js';

import "@/assets/js/script.js";

@Component({
  components: {
    Products,
    Resume,
    Teeth,
    Steps,
    Sidebar
  },
})
export default class New extends Vue {
  private dpNodesJSON: string = '';
  private dpNodes: any[] = [];

  private selectedNodeId: string = '';
  private selectedProductId: string = '';

  private finalProducts: any[] = [];

  mounted() {
    const xhr = new XMLHttpRequest();
    xhr.open('GET', 'https://backend.plibeo-dev-gamma.com/settings/version_graphics', true);
    xhr.send();
    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4 && xhr.status === 200) {
        // @ts-ignore
        this.dpNodesJSON = JSON.parse(JSON.parse(xhr.responseText).value);
        // @ts-ignore
        this.dpNodes = this.dpNodesJSON[Object.keys(this.dpNodesJSON).pop()].subNodes;
      }
    };
  }

  renderFinalProducts(nodes: any) {
    this.finalProducts = nodes;
  }

  selectProduct(productId: string) {
    globalEvents.$emit('unselect-all-teeth');
    this.selectedProductId = productId;

    if(!Vue.prototype.$state.productSelected) {
      Vue.prototype.$state.globalMessage = '';
      return;
    }

    if(Vue.prototype.$state.productSelected.custom && Vue.prototype.$state.productSelected.custom.requirements) {
      switch (Vue.prototype.$state.productSelected.custom.requirements) {
        case '1+':
          Vue.prototype.$state.globalMessage = 'Sélectionnez au moins une dent pour ce produit';
          break;
        case '2+':
          Vue.prototype.$state.globalMessage = 'Sélectionnez au moins deux dents pour ce produit';
          break;
        case '0':
          Vue.prototype.$state.globalMessage = 'Ce produit ne nécessite pas de sélection de dent';
          break;
        case '1':
          Vue.prototype.$state.globalMessage = 'Sélectionnez exactement une dent pour ce produit';
          break;
        default:
          Vue.prototype.$state.globalMessage = 'Sélectionnez au moins une dent pour ce produit';
          break;
      }
    } else {
      Vue.prototype.$state.globalMessage = 'Sélectionnez au moins une dent pour ce produit';
    }

    Vue.prototype.$state.currentWork = {
      workId: Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15),
      product: Vue.prototype.$state.productSelected,
      teeth: [18,17,16,15,14,13,12,11,21,22,23,24,25,26,27,28,48,47,46,45,44,43,42,41,31,32,33,34,35,36,37]
    }

    this.toothClick(38);
  }

  toothClick(tooth: number) {
    if(Vue.prototype.$state.currentWork) {
      if(Vue.prototype.$state.currentWork.teeth.includes(tooth)) {
        Vue.prototype.$state.currentWork.teeth = Vue.prototype.$state.currentWork.teeth.filter((t: number) => t !== tooth);
      } else {
        Vue.prototype.$state.currentWork.teeth.push(tooth);
      }

      // if at least one tooth, we update Vue.prototype.$state.works (using workId)
      if(Vue.prototype.$state.currentWork.teeth.length > 0) {
        const index = Vue.prototype.$state.works.findIndex((work: any) => work.workId === Vue.prototype.$state.currentWork.workId);
        if(index !== -1) {
          Vue.prototype.$state.works[index] = Vue.prototype.$state.currentWork;
        } else {
          Vue.prototype.$state.works.push(Vue.prototype.$state.currentWork);
        }
      } else {
        Vue.prototype.$state.works = Vue.prototype.$state.works.filter((work: any) => work.workId !== Vue.prototype.$state.currentWork.workId);
      }
    }
  }

  get globalMessage() {
    return Vue.prototype.$state.globalMessage;
  }
}
</script>

<style>
html, body {
  background-color: #E5E7E7;
  padding: 0;
  margin: 0;
}


body[norm=fdi] text.notation-universal {
  display: none;
}

body[norm=uns] text.notation-fdi {
  display: none;
}

body:not([norm=""]) text.notation-universal {
  display: none;
}

* {
  box-sizing: border-box;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.row {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.col {
  width: auto;
  flex: 1;
  gap: 10px;
}

.col-2 {
  width: 16.6666666667%;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes rainbow {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}

@keyframes smoothPurplePink {
  0%, 100% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
}

.globalMessage {
  height: 20px;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
  display: block;
  text-align: center;
  font-family: Raleway, sans-serif;

  /* Propriétés améliorées pour l'animation */
  background-image: linear-gradient(
      45deg,
      #6A0DAD, #8E44AD, #C71585, #FF69B4, #C71585, #8E44AD, #6A0DAD
  );
  background-size: 300% 300%;
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  animation: smoothPurplePink 8s ease-in-out infinite;
  text-shadow: 0 0 10px rgba(0,0,0,0.1);
}

.globalMessage:hover {
  animation-duration: 4s;
  cursor: pointer;
}
</style>